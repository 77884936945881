import { render, staticRenderFns } from "./ApiRelationSetting.vue?vue&type=template&id=c8c2711e&scoped=true&"
import script from "./ApiRelationSetting.vue?vue&type=script&lang=js&"
export * from "./ApiRelationSetting.vue?vue&type=script&lang=js&"
import style0 from "./ApiRelationSetting.vue?vue&type=style&index=0&id=c8c2711e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8c2711e",
  null
  
)

export default component.exports