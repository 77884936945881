<template>
  <div class="api-relation-setting">
    <p class="api-relation-setting__notice">
      <svg-icon icon-class="alert-circle-outline" />{{ $t('ssoSetting.followApiResponseStructure') }}
    </p>
    <div
      class="api-relation-setting__row"
      v-for="(row, i) in dataRows"
      :key="`row-${i}`"
    >
      <div class="api-relation-setting__row-title">
        {{ row.label }}
        <span v-if="row.require">*</span>
      </div>
      <div class="api-relation-setting__row-input">
        <InputBlock
          v-model.trim="row.value"
          :placeholder="`${$t('ssoSetting.insertDataPath')} (ex. ${row.example})`"
          type="text"
          :name="`api-relation-setting-${row.key}`"
          v-validate="row.key === 'identifier' ? 'required' : null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, inject } from '@vue/composition-api'
import InputBlock from '@/components/InputBlock.vue'
import { useI18n } from '@/utils/composable/i18n'

export default defineComponent({
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    InputBlock
  },
  inject: ['$validator'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const dataRows = ref([
      {
        label: 'Email',
        key: 'email',
        example: 'result.user.email',
        require: false,
        value: ''
      }, {
        label: 'Landing URL',
        key: 'landing_url',
        example: 'result.user.landing_url',
        require: false,
        value: ''
      }, {
        label: t('ssoSetting.name'),
        key: 'name',
        example: 'result.user.name',
        require: false,
        value: ''
      }, {
        label: t('ssoSetting.role'),
        key: 'role',
        example: 'result.user.role',
        require: false,
        value: ''
      }, {
        label: t('ssoSetting.project'),
        key: 'group_id',
        example: 'result.user.group_id',
        require: false,
        value: ''
      }, {
        label: t('ssoSetting.userId'),
        key: 'identifier',
        example: 'result.user.identifier',
        require: true,
        value: ''
      }
    ])

    watch(
      dataRows,
      (val) => {
        const returnObj = {}
        val.forEach(row => {
          if (!row.value) return null
          returnObj[row.key] = /^\$\./.test(row.value) ? row.value : `$.${row.value}`
        })
        emit('input', returnObj)
      },
      {
        deep: true
      }
    )

    const currentSsoSetting = inject('currentSsoSetting')
    const isSetted = ref(false)
    watch(
      currentSsoSetting,
      () => {
        if (!isSetted.value) {
          isSetted.value = true
          const defaultSetting = currentSsoSetting.value?.responseConfiguration
          if (defaultSetting) {
            const keyMap = dataRows.value.map((item) => item.key)
            Object.keys(defaultSetting).forEach((key) => {
              const index = keyMap.indexOf({ landingUrl: 'landing_url', groupId: 'group_id' }?.[key] || key)
              dataRows.value[index].value = defaultSetting[key] ? defaultSetting[key].replace('$.', '') : null
            })
          }
        }
      }
    )

    return {
      dataRows
    }
  }
})
</script>

<style lang="scss" scoped>
.api-relation-setting {
  max-width: 510px;
  position: relative;

  &__notice {
    align-items: center;
    color: #ffdf6f;
    display: flex;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0 0 20px;

    .svg-icon {
      display: block;
      height: 16px;
      margin-right: 4px;
      width: 16px;
    }
  }

  &__row {
    align-items: center;
    display: flex;

    & + & {
      margin-top: 20px;
    }

    &-title {
      color: #ccc;
      flex: 0 0 160px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.01em;
      line-height: 20px;

      span {
        color: #ffdf6f;
      }
    }

    &-input {
      flex: 1 1 0;
    }
  }
}
</style>
