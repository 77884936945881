<template>
  <div class="api-response-transform-condition">
    <button
      class="btn-m btn-outline btn-has-icon add-btn"
      @click.stop="createRecord"
    >
      <svg-icon
        icon-class="plus"
        class="icon"
      />
      {{ $t('ssoSetting.addRecord') }}
    </button>
    <div
      class="api-response-transform-condition__table"
      v-if="tableRow.length > 0"
    >
      <div class="api-response-transform-condition__head">
        <div
          class="api-response-transform-condition__row"
        >
          <div
            class="api-response-transform-condition__cell"
            v-for="(item, i) in tableHeadData"
            :key="`parameter-th-${i}`"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="api-response-transform-condition__body">
        <div
          class="api-response-transform-condition__row"
          v-for="(row, i) in tableRow"
          :key="`parameter-td-${i}`"
        >
          <div
            class="api-response-transform-condition__cell"
          >
            <DefaultSelect
              :option-list="returnTypeList"
              :placeholder="$t('ssoSetting.selectReturnTypePlaceholder')"
              :value="row.type"
              @change="handleTypeChange(i, $event)"
            />
          </div>
          <div
            class="api-response-transform-condition__cell"
          >
            <InputBlock
              v-model.trim="row.apiReturn"
              :placeholder="$t('ssoSetting.insertPlease')"
              type="text"
              v-validate="'required'"
              :name="`api-response-transform-condition-key-${i}`"
            />
          </div>
          <div
            class="api-response-transform-condition__cell"
          >
            <DefaultSelect
              v-if="row.type === 'group_id' || row.type === 'role'"
              :option-list="jarviXValueList(row.type)"
              :placeholder="$t('ssoSetting.selectReturnTypePlaceholder')"
              v-model="row.jarviX"
              v-validate="'required'"
            />
            <InputBlock
              v-else
              v-model.trim="row.jarviX"
              :placeholder="$t('ssoSetting.insertPlease')"
              type="text"
              v-validate="'required'"
              :name="`api-response-transform-condition-jarvix-input-${i}`"
            />
          </div>
          <div
            class="api-response-transform-condition__cell"
          >
            <div
              class="api-response-transform-condition__remove"
              @click.stop="removeRecord(i)"
            >
              <SvgIcon
                icon-class="delete"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p
      class="api-response-transform-condition__empty"
      v-else
    >
      {{ $t('ssoSetting.noConditionExist') }}
    </p>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, inject } from '@vue/composition-api'
import { Row, Col } from 'element-ui'
import { useMapState } from '@/utils/composable/vuex'
import { useI18n } from '@/utils/composable/i18n'
import InputBlock from '@/components/InputBlock.vue'
import DefaultSelect from '@/components/select/DefaultSelect'
import SvgIcon from '@/components/SvgIcon.vue'

export default defineComponent({
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Row,
    Col,
    InputBlock,
    DefaultSelect,
    SvgIcon
  },
  inject: ['$validator'],
  setup (props, { emit }) {
    const { t } = useI18n()

    // ===== const data =====
    const tableHeadData = [
      {
        text: t('ssoSetting.returnType')
      }, {
        text: t('ssoSetting.returnDataValue')
      }, {
        text: t('ssoSetting.jarvixTransformResult')
      }, {
        text: ''
      }
    ]

    const returnTypeList = [
      {
        name: t('ssoSetting.project'),
        value: 'group_id'
      }, {
        name: t('ssoSetting.role'),
        value: 'role'
      },
      {
        name: 'Email',
        value: 'email'
      }, {
        name: 'Landing URL',
        value: 'landing_url'
      }, {
        name: t('ssoSetting.name'),
        value: 'name'
      }
    ]

    const { groupList } = useMapState('userManagement', ['groupList'])
    const groupListData = computed(() => {
      return groupList.value
        .map(group => ({
          name: group.groupName,
          value: group.groupId.toString()
        }))
        .sort((groupOne, groupTwo) => (groupOne.name.toLowerCase() > groupTwo.name.toLowerCase()) ? 1 : -1)
    })

    const roleListData = [
      {
        name: t('userManagement.groupOwner'),
        value: 'group_owner'
      }, {
        name: t('userManagement.groupViewer'),
        value: 'group_viewer'
      }
    ]

    function jarviXValueList (key) {
      if (key === 'group_id') {
        return groupListData.value
      } else if (key === 'role') {
        return roleListData
      }
    }

    // ===== table data =====
    const tableRow = ref([])
    function createRecord () {
      tableRow.value.push({
        type: 'group_id',
        apiReturn: '',
        jarviX: groupListData.value[0].value
      })
    }
    function removeRecord (i) {
      tableRow.value.splice(i, 1)
    }

    function handleTypeChange (i, type) {
      tableRow.value[i].type = type
      tableRow.value[i].jarviX = ''
      if (tableRow.value[i].type === 'group_id' || tableRow.value[i].type === 'role') {
        tableRow.value[i].jarviX = jarviXValueList(tableRow.value[i].type)[0].value
      }
    }

    watch(
      tableRow,
      () => {
        emit('input', tableRow.value)
      }
    )

    const currentSsoSetting = inject('currentSsoSetting')
    const isSetted = ref(false)
    watch(
      () => currentSsoSetting.value,
      () => {
        if (!isSetted.value) {
          isSetted.value = true
          const defaultSetting = currentSsoSetting.value?.ssoPropertyMapping
          if (defaultSetting && defaultSetting.length > 0) {
            tableRow.value = defaultSetting.map(item => {
              return {
                type: item.property,
                apiReturn: item.responseValue,
                jarviX: item.jarvixValue
              }
            })
          }
        }
      }
    )

    return {
      groupListData,
      tableHeadData,
      returnTypeList,
      roleListData,
      tableRow,
      jarviXValueList,
      createRecord,
      removeRecord,
      handleTypeChange
    }
  }
})
</script>

<style lang="scss" scoped>
.api-response-transform-condition {
  &__table {
    margin-top: 16px;
    position: relative;
  }

  &__head {
    background-color: #2b4d51;
    color: #fff;
    text-align: left;

    .api-response-transform-condition__cell {
      padding: 4px 8px;
    }
  }

  &__body {
    .api-response-transform-condition__row {
      &:nth-child(2n) {
        background: rgba(50, 75, 78, 0.6);
      }

      &:nth-child(2n + 1) {
        background: rgba(35, 61, 64, 0.6);
      }
    }

    .api-response-transform-condition__cell {
      padding: 12px 8px;
    }
  }

  &__row {
    display: flex;
    padding: 0 8px;
  }

  &__cell {
    align-items: center;
    display: flex;
    flex: 0 1 216px;
    justify-content: flex-start;

    &:last-child {
      flex: 1 1 0;
      justify-content: flex-end;
    }

    .el-select {
      width: 100%;

      ::v-deep .el-input__inner {
        padding-left: 0;
      }
    }

    .input-block {
      width: 100%;
    }
  }

  &__remove {
    cursor: pointer;
  }

  &__empty {
    color: #999;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 20px;
    margin: 16px 0 0;
  }
}

</style>
