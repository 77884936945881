<template>
  <div class="sso-setting">
    <div class="sso-setting__head">
      <div class="page-title-row">
        <h1 class="title">
          {{ $t('ssoSetting.title') }}
        </h1>
      </div>
      <div class="sso-setting__head-actions">
        <div class="sso-setting__head-actions-item">
          <p class="sso-setting__head-status">
            SSO ID：<span class="sso-setting__head-status-context">{{ ssoId === null ? $t('ssoSetting.noContent') : ssoId }}</span>
          </p>
        </div>
        <div class="sso-setting__head-actions-item">
          <p class="sso-setting__head-status">
            {{ $t('ssoSetting.activeStatus') }}：
            <el-switch
              v-model="isSsoActive"
              :width="32"
              active-color="#2AD2E2"
              inactive-color="#324B4E"
            />
            <span class="sso-setting__head-status-context">{{ isSsoActive ? $t('ssoSetting.statusActive') : $t('ssoSetting.statusDeactivate') }}</span>
          </p>
        </div>
        <div class="sso-setting__head-actions-item">
          <button
            class="btn-m btn-default"
            @click.stop="submitSetting"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
    <div class="sso-setting__content">
      <div class="sso-setting__group">
        <p class="sso-setting__group-title">
          {{ $t('ssoSetting.apiCallSetting') }} <span class="sso-setting__group-title-highlight">({{ $t('editing.isRequired') }})</span>
        </p>
        <div class="sso-setting__group-section -setting">
          <Row
            :gutter="16"
            type="flex"
            align="middle"
          >
            <Col :span="3">
              API URL：
            </Col>
            <Col :span="21">
              <InputBlock
                v-model.trim="apiCallSetting.url"
                :placeholder="$t('ssoSetting.apiUrlPlaceholder')"
                type="email"
                name="apiUrl"
                v-validate="'required'"
              />
            </Col>
          </Row>
          <Row
            :gutter="16"
            type="flex"
            align="middle"
          >
            <Col :span="3">
              {{ $t('ssoSetting.postTypeTitle') }}
            </Col>
            <Col :span="21">
              <!-- v-validate="'required'" -->
              <!-- :is-disabled="isLoading" -->
              <DefaultSelect
                :option-list="callMethods"
                :placeholder="$t('ssoSetting.postTypePlaceholder')"
                v-model="apiCallSetting.methods"
                class="input-field__select"
                name="dataSourceId"
                @change="setSelectMethod"
              />
            </Col>
          </Row>
        </div>
        <p class="sso-setting__group-title -parameter">
          {{ $t('ssoSetting.apiCallParamsSettingTitle') }} <span class="sso-setting__group-title-highlight">({{ $t('editing.isRequired') }})</span>
        </p>
        <div class="sso-setting__group-section">
          <ApiCallParamsSetting v-model="apiCallParamsSetting" />
        </div>
      </div>
      <div class="sso-setting__group">
        <p class="sso-setting__group-title">
          {{ $t('ssoSetting.apiRelationSettingTitle') }}
        </p>
        <div class="sso-setting__group-section">
          <ApiRelationSetting v-model="apiRelationSetting" />
        </div>
        <p class="sso-setting__group-title">
          {{ $t('ssoSetting.customTransformConditionTitle') }}
        </p>
        <div class="sso-setting__group-section">
          <ApiResponseTransformCondition v-model="apiResponseTransformCondition" />
        </div>
      </div>
      <button
        class="btn-m btn-default"
        @click.stop="submitSetting"
      >
        {{ $t('button.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/row.css'

import { defineComponent, ref, computed, onMounted, provide } from '@vue/composition-api'
import { Row, Col, Message } from 'element-ui'

import { useValidator } from '@/utils/composable/validator'
import { useMapGetters } from '@/utils/composable/vuex'
import { useI18n } from '@/utils/composable/i18n'

import { getSsoSetting, createSsoSetting, updateSsoSetting } from '@/API/SSO'

import InputBlock from '@/components/InputBlock.vue'
import DefaultSelect from '@/components/select/DefaultSelect'
import ApiCallParamsSetting from './components/ApiCallParamsSetting.vue'
import ApiRelationSetting from './components/ApiRelationSetting.vue'
import ApiResponseTransformCondition from './components/ApiResponseTransformCondition.vue'

export default defineComponent({
  name: 'SsoSetting',
  components: {
    Row,
    Col,
    InputBlock,
    DefaultSelect,
    ApiCallParamsSetting,
    ApiRelationSetting,
    ApiResponseTransformCondition
  },
  inject: ['$validator'],
  setup () {
    const validator = useValidator()
    const { t } = useI18n()

    // ===== const value =====
    const { getCurrentAccountId: accountId } = useMapGetters('userManagement', ['getCurrentAccountId'])
    const callMethods = [
      {
        name: 'POST',
        value: 'post'
      }, {
        name: 'GET',
        value: 'get'
      }
    ]

    // ===== binding value =====
    const ssoId = ref(null)
    const isSsoActive = ref(false)
    const apiCallSetting = ref({
      url: '',
      methods: 'post'
    })
    const apiCallParamsSetting = ref([])
    const apiRelationSetting = ref({})
    const apiResponseTransformCondition = ref([])

    function setSelectMethod (value) {
      apiCallSetting.value.methods = value
    }

    const postData = computed(() => {
      return {
        accountId: accountId.value,
        active: !!isSsoActive.value,
        requestConfiguration: {
          method: apiCallSetting.value.methods,
          param: apiCallParamsSetting.value.map((item) => {
            return {
              name: item.key,
              type: item.type,
              valueFromParam: item.jarvixKey
            }
          }),
          url: apiCallSetting.value.url
        },
        responseConfiguration: apiRelationSetting.value,
        ssoPropertyMapping: apiResponseTransformCondition.value.map((item) => ({
          jarvixValue: item.jarviX,
          property: item.type,
          responseValue: item.apiReturn
        }))
      }
    })

    // ===== handler function =====
    async function submitSetting () {
      const valid = await validator.validateAll()
      if (valid) {
        try {
          if (ssoId.value !== null) {
            await updateSsoSetting(ssoId.value, postData.value)
          } else {
            const res = await createSsoSetting(postData.value)
            ssoId.value = res
          }
          Message.success(t('message.saveSuccess'))
        } catch (err) {
          console.log(err)
        }
      }
    }

    // ===== hooks =====
    const currentSsoSetting = ref(null)
    provide('currentSsoSetting', currentSsoSetting)
    onMounted(async () => {
      const res = await getSsoSetting(accountId.value)
      ssoId.value = res.ssoSettingId
      isSsoActive.value = !!res.active
      if (res.requestConfiguration) {
        apiCallSetting.value.url = res.requestConfiguration.url
        apiCallSetting.value.methods = res.requestConfiguration.method
      }
      currentSsoSetting.value = JSON.parse(JSON.stringify(res))
    })

    return {
      callMethods,
      ssoId,
      isSsoActive,
      apiCallSetting,
      apiCallParamsSetting,
      apiRelationSetting,
      apiResponseTransformCondition,
      setSelectMethod,
      submitSetting
    }
  }
})
</script>

<style lang="scss">
.sso-setting {
  &__group-section {
    &.-setting {
      .el-select {
        .el-input {
          .el-input__inner {
            padding-left: 0;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.sso-setting {
  &__head {
    display: flex;
    justify-content: space-between;

    &-actions {
      align-items: center;
      display: flex;

      &-item {
        & + & {
          margin-left: 20px;
        }
      }
    }

    &-status {
      color: #ccc;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.01em;
      line-height: 20px;

      &-context {
        color: #fff;
      }

      .el-switch {
        margin-right: 8px;
      }
    }
  }

  &__content {
    position: relative;
  }

  &__group {
    background: var(--color-bg-5);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    margin-bottom: 16px;
    padding: 24px 20px;

    &-title {
      font-size: 20px;
      line-height: 32px;
      margin: 0 0 8px 15px;
      position: relative;

      &-highlight {
        color: #ffdf6f;
      }

      &::before {
        background: #4de2f0;
        content: '';
        height: 6px;
        left: -13px;
        position: absolute;
        top: 13px;
        width: 6px;
      }
    }

    &-section {
      background: #141c1d;
      border-radius: 5px;
      padding: 20px;

      + .sso-setting__group-title {
        margin-top: 16px;
      }

      &.-setting {
        .input-block {
          width: 350px;
        }

        .el-select {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  .el-row {
    + .el-row {
      margin-top: 16px;
    }
  }
}
</style>
