import request from '@/utils/publicRequest'

/**
 * SSO
 * @param {Object} accountInfo - 要驗證的帳戶資訊
 * @param {String} accountInfo.ssoSettingId - 客戶 id
 * @param {String} accountInfo.token - 使用者 token
 */
export function externalCustomerLogin (accountInfo) {
  const { ssoSettingId, ...restParams } = accountInfo

  return request({
    baseURL: window.env.SSO_API_ROOT_URL,
    url: '/sso/authorize',
    method: 'POST',
    params: restParams,
    data: {
      ssoSettingId
    }
  })
}

/**
 * 取得 SSO 設定
 * @param {Number} accountId - 帳戶 id
 */
export function getSsoSetting (accountId) {
  return request({
    url: `/sso/account/${accountId}`,
    method: 'GET'
  })
}

/**
 * 建立 SSO 設定
 * @param {object} data - post data
 */
export function createSsoSetting (data) {
  return request({
    url: '/sso',
    method: 'POST',
    data
  })
}

/**
 * 更新 SSO 設定
 * @param {Number} ssoId
 * @param {object} data - update data
 */
export function updateSsoSetting (ssoId, data) {
  return request({
    url: `/sso/${ssoId}`,
    method: 'PUT',
    data
  })
}
