<template>
  <div class="api-call-params-setting">
    <button
      class="btn-m btn-outline btn-has-icon add-btn"
      @click.stop="createRecord()"
    >
      <svg-icon
        icon-class="plus"
        class="icon"
      />
      {{ $t('ssoSetting.addRecord') }}
    </button>
    <div class="api-call-params-setting__table">
      <div class="api-call-params-setting__head">
        <div
          class="api-call-params-setting__row"
        >
          <div
            class="api-call-params-setting__cell"
            v-for="(item, i) in tableHeadData"
            :key="`parameter-th-${i}`"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="api-call-params-setting__body">
        <div
          class="api-call-params-setting__row"
          v-for="(row, i) in apiSettingTableRow"
          :key="`parameter-td-${i}`"
        >
          <div
            class="api-call-params-setting__cell"
          >
            <DefaultSelect
              :option-list="apiTypeList"
              v-model="row.type"
              @change="row.type = $event"
            />
          </div>
          <div
            class="api-call-params-setting__cell"
          >
            <InputBlock
              v-validate="'required'"
              v-model.trim="row.key"
              :placeholder="`${$t('ssoSetting.apiParamsNamePlaceholder')}(ex. token)`"
              type="text"
              :name="`api-call-params-setting-key-${i}`"
            />
          </div>
          <div
            class="api-call-params-setting__cell"
          >
            <InputBlock
              v-validate="'required'"
              v-model.trim="row.jarvixKey"
              :placeholder="$t('ssoSetting.apiParamsJarvixPlaceholder')"
              type="text"
              :name="`api-call-params-setting-jarvix-key-${i}`"
            />
          </div>
          <div
            class="api-call-params-setting__cell"
          >
            <div
              class="api-call-params-setting__remove"
              @click.stop="removeRecord(i)"
            >
              <SvgIcon
                v-if="apiSettingTableRow.length > 1"
                icon-class="delete"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, computed, inject } from '@vue/composition-api'
import { Row, Col } from 'element-ui'
import { useI18n } from '@/utils/composable/i18n'
import InputBlock from '@/components/InputBlock.vue'
import DefaultSelect from '@/components/select/DefaultSelect'
import SvgIcon from '@/components/SvgIcon.vue'

export default defineComponent({
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Row,
    Col,
    InputBlock,
    DefaultSelect,
    SvgIcon
  },
  inject: ['$validator'],
  setup (props, { emit }) {
    const { t } = useI18n()

    // ===== const data =====
    const tableHeadData = computed(() => ([
      {
        text: t('ssoSetting.apiParamsType')
      }, {
        text: t('ssoSetting.apiParamsName')
      }, {
        text: t('ssoSetting.apiParamsJarvix')
      }, {
        text: ''
      }
    ]))
    const apiTypeList = ref([
      {
        name: 'URL',
        value: 'url'
      }, {
        name: 'HEADER',
        value: 'header'
      }
    ])

    // ===== table data =====
    const apiSettingTableRow = ref([])
    function createRecord (type = 'URL', key = null, jarvixKey = null) {
      apiSettingTableRow.value.push({ type, key, jarvixKey })
    }
    function removeRecord (i) {
      apiSettingTableRow.value.splice(i, 1)
    }

    watch(
      apiSettingTableRow,
      () => {
        emit('input', apiSettingTableRow.value)
      }, {
        deep: true
      })

    // ===== hooks =====
    onMounted(() => {
      if (apiSettingTableRow.value.length === 0) {
        createRecord()
      }
    })

    const currentSsoSetting = inject('currentSsoSetting')
    const isSetted = ref(false)
    watch(
      currentSsoSetting,
      () => {
        if (!isSetted.value) {
          isSetted.value = true
          const defaultSetting = currentSsoSetting.value?.requestConfiguration?.param
          if (defaultSetting && defaultSetting.length > 0) {
            apiSettingTableRow.value = defaultSetting.map(item => ({
              type: item.type,
              key: item.name,
              jarvixKey: item.valueFromParam
            }))
          }
        }
      }
    )

    return {
      tableHeadData,
      apiTypeList,
      apiSettingTableRow,
      createRecord,
      removeRecord
    }
  }
})
</script>

<style lang="scss" scoped>
.api-call-params-setting {
  &__table {
    margin-top: 16px;
    position: relative;
  }

  &__head {
    background-color: #2b4d51;
    color: #fff;
    text-align: left;

    .api-call-params-setting__cell {
      padding: 4px 8px;
    }
  }

  &__body {
    .api-call-params-setting__row {
      &:nth-child(2n) {
        background: rgba(50, 75, 78, 0.6);
      }

      &:nth-child(2n + 1) {
        background: rgba(35, 61, 64, 0.6);
      }
    }

    .api-call-params-setting__cell {
      padding: 12px 8px;
    }
  }

  &__row {
    display: flex;
    padding: 0 8px;
  }

  &__cell {
    align-items: center;
    display: flex;
    flex: 0 1 216px;
    justify-content: flex-start;

    &:last-child {
      flex: 1 1 0;
      justify-content: flex-end;
    }

    .el-select {
      width: 100%;

      ::v-deep .el-input__inner {
        padding-left: 0;
      }
    }

    .input-block {
      width: 100%;
    }
  }

  &__remove {
    cursor: pointer;
  }
}

</style>
